import { UserContactInfo } from '@maverick/entity';
import { MOBILE_BREAKPOINT } from '@maverick/utils';

const ARROW_DEFAULT_POSITION_OFFSET = 36;
const ANCHOR_RIGHT_OFFSET = 80;
const TOOLTIP_SIZE = 321;
const ARROW_SIZE = 30;
const NAME_MAX_LENGTH = 12;

export const retrieveArrowOffsetPosition = () => {
	const windowWidth = window.innerWidth;
	if (windowWidth > MOBILE_BREAKPOINT) {
		return ARROW_DEFAULT_POSITION_OFFSET;
	}
	const anchor = document.getElementById('user-account-container');
	if (!anchor) return;
	const rightOffset = ANCHOR_RIGHT_OFFSET + anchor.offsetWidth / 2;
	const tooltipMargin = (windowWidth - TOOLTIP_SIZE) / 2;
	const arrowOffset = rightOffset - tooltipMargin - ARROW_SIZE / 2;
	return arrowOffset;
};

export const retrieveDisplayName = (
	userInfo: UserContactInfo | null,
	isAuthenticated: boolean,
	isSmallScreen: boolean
) => {
	if (!userInfo || !isAuthenticated) {
		return 'Sign in';
	}

	if (isSmallScreen) {
		return 'Account';
	}

	const userName = userInfo.firstName;
	if (!userName || userName.length > NAME_MAX_LENGTH) {
		return userName.substring(0, NAME_MAX_LENGTH) + '...';
	}
	return userName;
};

export const handleKeyDown = (ev: React.KeyboardEvent, action: () => void) => {
	if (ev.key === 'Enter' || ev.key === ' ') {
		action();
		ev.stopPropagation();
		ev.preventDefault();
	}
};