import { FC, useEffect, useLayoutEffect, useState } from 'react';
import * as Styled from './ProfileButton.styled';
import { useAuth0 } from '@auth0/auth0-react';
import { AnimatedIcon, Button, Icon, Loading, Tooltip } from '@maverick/ui';
import { useTheme } from 'styled-components';
import { GaEvent } from '@maverick/utils';
import { ReduxProps } from './index';
import { useRouter } from 'next/router';
import { Config } from '../../../../Config';
import { retrieveArrowOffsetPosition, retrieveDisplayName, handleKeyDown } from './ProfileButton.utils';
import { useIsMobile } from '@maverick/hooks';

const userPages = ['/recent-orders', '/my-account', '/faves', '/my-rewards', '/my-activity'];

export interface ProfileButtonProps extends ReduxProps {}

export const ProfileButton: FC<ProfileButtonProps> = ({
	setUserAuthLastInteraction,
	setRedirectRoute,
	setUserLogged,
	userLogged,
	userInfo,
}) => {
	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
	const router = useRouter();
	const { orderUi } = useTheme();
	const [showedIcon, setShowedIcon] = useState<JSX.Element>(
		<Icon icon={orderUi.icons.iconUserHeader} customSize testId='user-icon' ariaLabel='view profile' />
	);
	const [arrowOffsetPosition, setArrowOffsetPosition] = useState<number | undefined>();
	const isSmallScreen = useIsMobile(768);

	useLayoutEffect(() => {
		const onResize = () => {
			const arrowOffset = retrieveArrowOffsetPosition();
			setArrowOffsetPosition(arrowOffset);
		};

		setTimeout(() => {
			onResize();
		}, 1000);

		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			if (userLogged !== isAuthenticated) {
				setShowedIcon(
					<AnimatedIcon
						icon={orderUi.icons.iconUserLoggedAnimation}
						onAnimationComplete={handleAnimationComplete}
						testId='user-icon'
					/>
				);
				setUserLogged(isAuthenticated);
			} else {
				setShowedIcon(
					<Icon
						icon={orderUi.icons.iconUserLoggedHeader}
						ariaLabel='view profile'
						customSize
						testId='user-icon'
					/>
				);
			}
		}
	}, [isAuthenticated]);

	const handleAnimationComplete = () => {
		setShowedIcon(
			<Icon icon={orderUi.icons.iconUserLoggedHeader} ariaLabel='view profile' customSize testId='user-icon' />
		);
	};

	const GaTopNavigationEvent = (label: string) => {
		GaEvent.TopNavigation(label);
	};

	const handleLogin = (prompt: string) => {
		setUserAuthLastInteraction('navbar');
		if (isLoading) {
			return;
		}
		GaTopNavigationEvent('Login');
		setRedirectRoute(location.pathname);
		if (prompt === 'login') {
			loginWithRedirect({ source: Config.Source, prompt: 'login' });
		} else {
			loginWithRedirect({ source: Config.Source });
		}
	};

	const renderTooltipContent = () => {
		return (
			<Styled.TooltipContainer data-testid='profile-button-tooltip'>
				<Styled.TooltipTitle tabIndex={0}>{'SIGN IN OR JOIN NOW'}</Styled.TooltipTitle>
				<Styled.TooltipText tabIndex={0}>
					{'Sign in or join Dine Rewards now to save your favorites and quickly reorder.'}
				</Styled.TooltipText>
				<Styled.TooltipButtons>
					<Button
						text='Sign in'
						variant='primary'
						padding='8px 0'
						id='sign-in'
						revertIcon
						onClick={() => handleLogin('login')}
						variantSize='buttonSmall'
					/>
					<Button
						text='Join now'
						variant='terciary'
						padding='8px 0'
						id='join-now'
						revertIcon
						onClick={() => handleLogin('join-now')}
						variantSize='buttonSmall'
					/>
				</Styled.TooltipButtons>
			</Styled.TooltipContainer>
		);
	};

	const handleClickProfile = () => {
		if (!isAuthenticated) {
			return;
		}
		if (!userPages.includes(location.pathname)) {
			setRedirectRoute(location.pathname);
		}
		router.push('/order/my-account');
	};

	return (
		<Tooltip
			id='profile-icon-tooltip'
			variant='light'
			content={renderTooltipContent()}
			arrowOffsetPosition={arrowOffsetPosition}
			disabled={isAuthenticated}
			tabIndex={0}
		>
			<Styled.UserAccount
				tabIndex={isAuthenticated ? 0 : -1}
				data-testid='user-account-container'
				id='user-account-container'
				aria-label={`User Account - ${isAuthenticated ? `Logged In` : `Logged Out`}`}
				onClick={handleClickProfile}
				{...(isAuthenticated && {
					onKeyDown: (ev) => handleKeyDown(ev, handleClickProfile),
				})}
			>
				<Styled.SignIn>
					<Styled.AnimatedIconContainer
						id='animated-icon-container'
						data-testid='animated-icon-container'
						isLoading={isLoading}
					>
						{isLoading ? <Loading size={14} color='#fff' /> : showedIcon}
					</Styled.AnimatedIconContainer>
					<Styled.SignInText>
						{retrieveDisplayName(userInfo, isAuthenticated, isSmallScreen)}
					</Styled.SignInText>
				</Styled.SignIn>
			</Styled.UserAccount>
		</Tooltip>
	);
};
