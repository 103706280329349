import { Icon, Tooltip, useTooltip } from '@maverick/ui';
import { FC, useState, useEffect } from 'react';
import { useCookies, useInterval } from '@maverick/hooks';
import { formatTime, GaEvent, getDateWithOffset } from '@maverick/utils';
import { differenceInMilliseconds } from 'date-fns';
import { LocationReduxProps } from '.';
import { setCookie } from '@maverick/ui';
import { Calendar, Cookies } from '@maverick/entity';
import { LocationManager } from '@maverick/cms';
import { LocationIcon } from '@maverick/icons/dist/LocationIcon';
import * as Styled from './Location.styled';
import { LocationPopUp } from './LocationPopUp/LocationPopUp';

const refreshInterval = Number(process.env.LOCATION_REFRESH_INTERVAL) ?? 3600;

interface LocationProps extends LocationReduxProps {}

export const Location: FC<LocationProps> = ({ seatingData, restaurant, setRestaurant }) => {
	const [open, setOpen] = useState<boolean | null>(null);
	const [tableWait, setTableWait] = useState<string | null>(null);
	const [hasSeating, setHasSeating] = useState<boolean>(false);
	const [yextUrl, setYextUrl] = useState<string | null>(null);
	const [favoriteStore] = useCookies(Cookies.FavoriteStore);
	const { closeTooltip } = useTooltip('header-location-tooltip');

	const handleModal = () => {
		closeTooltip();
	};

	useEffect(() => {
		initFavoriteStore();
	}, [favoriteStore]);

	useEffect(() => {
		handleSec();
	}, [restaurant]);

	useEffect(() => {
		handleSeating();
	}, [seatingData, restaurant]);

	useInterval(() => {
		handleSeating();
		handleSec();
	}, refreshInterval * 1000);

	const initFavoriteStore = async () => {
		if (!favoriteStore) {
			setRestaurant(null);
			GaEvent.Global.Set('store_id', 'not selected');
			return;
		}

		const restaurant = await LocationManager.GetLocationByRef(favoriteStore);

		if (!restaurant) {
			setRestaurant(null);
			GaEvent.Global.Set('store_id', 'not selected');
			return;
		}

		setRestaurant(restaurant);
		GaEvent.Global.Set('store_id', Number(favoriteStore));
	};

	const handleSec = async () => {
		if (!favoriteStore || !restaurant) {
			return;
		}

		await Promise.all([handleYextUrl(), handleRestaurantOpen(), handleTableWait()]);
	};

	const handleYextUrl = async () => {
		const result = await LocationManager.GetLocationInfo(favoriteStore!);
		if (!result) return;

		if (result.yextUrl) {
			setYextUrl(result.yextUrl);
		}

		setCookie(Cookies.WhitelabelUrl, restaurant!.url);
	};

	const handleRestaurantOpen = async () => {
		if (!restaurant) return;

		if (restaurant?.calendars) return;

		const result = await LocationManager.GetLocationCalendars(restaurant.id);
		if (!result) {
			return;
		}

		const now = getDateWithOffset(restaurant.utcoffset);
		const isOpen = result.businessCalendar?.ranges.some((r) => now >= r.start && now <= r.end) ?? null;

		const calendar = () => {
			const calendarArray = [result.businessCalendar, result.curbsideCalendar, result.deliveryCalendar];
			const resultArray: Calendar[] = [];
			calendarArray.forEach((c) => {
				if (!!c) resultArray.push(c);
			});

			return resultArray.length > 0 ? resultArray : undefined;
		};

		const newRestaurant = { ...restaurant, calendars: calendar() };
		setRestaurant(newRestaurant);
		setOpen(isOpen);
	};

	const handleTableWait = async () => {
		const result = await LocationManager.GetTableWait(favoriteStore!);
		if (!result) {
			return;
		}

		setTableWait(result.waitTime);
	};

	const handleSeating = async () => {
		if (!seatingData?.PartyKey || !seatingData?.Datetime) {
			setHasSeating(false);
			return;
		}

		if (seatingData.PartyKey && restaurant) {
			const restaurantDate = getDateWithOffset(restaurant.utcoffset);
			const isInTheFuture = differenceInMilliseconds(seatingData.Datetime, restaurantDate) > 0;
			if (!isInTheFuture) {
				setHasSeating(false);
			} else {
				setHasSeating(true);
			}
		}
	};

	const renderSeatingDetails = () => {
		const [hours, minutes] = seatingData?.TimeSlot?.split(':') || ['', ''];
		if (hours === '' || minutes === '' || !hasSeating || !seatingData) {
			setHasSeating(false);
			return '';
		}
		return `Party of ${seatingData.Guests}, ${formatTime(hours, minutes)}`;
	};

	const renderPopUp = () => {
		return (
			<LocationPopUp
				restaurant={restaurant!}
				status={open}
				tableWait={tableWait}
				yext={yextUrl}
				seatingDetails={hasSeating && seatingData ? renderSeatingDetails() : null}
				handleModal={handleModal}
			/>
		);
	};

	return (
		<Styled.Container>
			{!restaurant ? (
				<Styled.LocationContainer
					href='https://locations.outback.com/search'
					data-testid='no-location'
					onClick={() => {
						GaEvent.TopNavigation('Find location');
						GaEvent.OutboundLinkClick('https://locations.outback.com/search');
					}}
					aria-label='edit location'
				>
					<Styled.LocationIcon>
						<Icon icon={LocationIcon} customSize />
					</Styled.LocationIcon>
					<Styled.Name data-testid='find-location' hasLocation={false}>
						Find location
					</Styled.Name>
				</Styled.LocationContainer>
			) : (
				<Tooltip content={renderPopUp()} variant='light' centered id='header-location-tooltip'>
					<Styled.LocationContainer>
						<Styled.LocationIcon>
							<Icon icon={LocationIcon} customSize />
						</Styled.LocationIcon>
						<Styled.Content data-testid='content' tabIndex={0}>
							<Styled.Name data-testid='location-name' hasLocation={true}>
								{restaurant.name}
							</Styled.Name>
						</Styled.Content>
					</Styled.LocationContainer>
				</Tooltip>
			)}
		</Styled.Container>
	);
};
