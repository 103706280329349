import styled from 'styled-components';

export const Container = styled.div``;

export const HeaderButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;

	@media (max-width: 768px) {
		gap: 16px;
	}
`;
