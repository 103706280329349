import styled, { css } from 'styled-components';

export const Icon = styled.div<{ isLoading?: boolean }>`
	height: 24px;
	max-width: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all ease 0.1s;
	position: relative;

	& > *:first-child {
		color: ${({ theme }) => theme.orderUi.icons.iconHeaderColor};
	}

	${({ isLoading }) =>
		isLoading &&
		css`
			cursor: default;

			&:hover {
				opacity: 1;
			}
		`}
`;

export const BasketButton = styled.button`
	background: none;
	border: none;
	padding: 5px 8px;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	cursor: pointer;

	@media (max-width: 767px) {
		padding: 5px 8px;
	}

	&:hover {
		${Icon} {
			opacity: 0.6;
		}
	}
`;

export const BasketBadge = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1.8px solid;
	background: ${({ theme }) => theme.orderUi.colors.primary.light};
	${({ theme }) => theme.orderUi.typography.caption.caption};
	font-weight: 700;
	position: absolute;
	top: -4px;
	right: -6px;
`;
