import { connect, ConnectedProps } from 'react-redux';
import { AppOperations, RootState } from '@maverick/store';
import { BasketButton } from './BasketButton';

const mapStateToProps = (state: RootState) => ({
	basket: state.checkout.basket,
	restaurant: state.location.restaurant,
});

const mapDispatchToProps = {
	setShowSummary: AppOperations.setShowSummary,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

const BasketButtonConnected = connector(BasketButton);
export { BasketButtonConnected as BasketButton };
