import { Location } from './Location';
import { connect, ConnectedProps } from 'react-redux';
import { LocationOperations, RootState } from '@maverick/store';

const mapStateToProps = (state: RootState) => ({
	seatingData: state.seating.seatingData,
	restaurant: state.location.restaurant,
});

const mapDispatchToProps = {
	setRestaurant: LocationOperations.setRestaurant, 
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type LocationReduxProps = ConnectedProps<typeof connector>;
const LocationConnected = connector(Location);
export { LocationConnected as Location };
