import { FC } from 'react';
import * as Styled from './HeaderInteraction.styled';
import { BasketButton } from './BasketButton';
import { ProfileButton } from './ProfileButton';

export const HeaderInteraction: FC = () => {
	return (
		<>
			<Styled.Container>
				<Styled.HeaderButtons>
					<ProfileButton />
					<BasketButton />
				</Styled.HeaderButtons>
			</Styled.Container>
		</>
	);
};
