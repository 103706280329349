import { connect, ConnectedProps } from 'react-redux';
import { AppOperations, RootState, UserOperations } from '@maverick/store';
import { ProfileButton } from './ProfileButton';

const mapStateToProps = (state: RootState) => ({
	userLogged: state.user.userLogged,
	userInfo: state.user.userInfo,
});

const mapDispatchToProps = {
	setRedirectRoute: AppOperations.setRedirectRoute,

	setUserLogged: UserOperations.setUserLogged,
	setUserAuthLastInteraction: UserOperations.setUserAuthLastInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

const ProfileButtonConnected = connector(ProfileButton);
export { ProfileButtonConnected as ProfileButton };
