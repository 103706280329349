import { Restaurant } from '@maverick/entity';
import { Button, Icon } from '@maverick/ui';
import { filterRestaurantHours, GaEvent, handleRestaurantInfo } from '@maverick/utils';
import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { IconClock } from '@maverick/icons/dist/IconClock';
import * as Styled from './LocationPopUp.styled';
import Link from 'next/link';

interface LocationPopUpProps {
	restaurant: Restaurant;
	status: boolean | null;
	tableWait: string | null;
	yext: string | null;
	seatingDetails: string | null;
	handleModal: () => void;
}

export const LocationPopUp: FC<LocationPopUpProps> = ({
	restaurant,
	status,
	tableWait,
	yext,
	seatingDetails,
	handleModal,
}) => {
	const { cmsComponents } = useTheme();

	const [restaurantInfo, setRestaurantInfo] = useState({
		restaurantName: null as string | null,
		restaurantAddress: null as string | null,
		restaurantHours: null as string | null | undefined,
	});

	useEffect(() => {
		const { restaurantName, restaurantAddress } = handleRestaurantInfo(restaurant);
		const restaurantHours =
			restaurant.calendars && restaurant.calendars.length > 0
				? filterRestaurantHours(restaurant.calendars)
				: null;
		setRestaurantInfo({
			restaurantName,
			restaurantAddress,
			restaurantHours,
		});
	}, [restaurant]);

	const renderSeatingDetails = () => {
		if (seatingDetails) {
			return (
				<Styled.SeatingContainer>
					<Styled.SeatingDetails data-testid='location-seating-details' aria-label={`${seatingDetails}`}>
						<Link
							href='/seating/details'
							onClick={() => {
								GaEvent.TopNavigation('details');
								GaEvent.InternalLinkClick('/seating/details');
							}}
						>
							{seatingDetails}
						</Link>
					</Styled.SeatingDetails>
				</Styled.SeatingContainer>
			);
		} else if (status !== null) {
			return (
				<Styled.SeatingContainer>
					<Styled.SeatingStatus
						data-testid='location-seating-status'
						status={status}
						aria-label={`${status}`}
						tabIndex={0}
					>
						{status ? 'Open' : 'Closed'}
					</Styled.SeatingStatus>
					<Styled.SeatingDetails data-testid='location-wait-time'>
						&nbsp;/&nbsp;
						<Link
							href='/seating'
							onClick={() => {
								GaEvent.TopNavigation('wait time');
								GaEvent.InternalLinkClick('/seating');
								handleModal();
							}}
						>
							{status && tableWait !== null && tableWait.toString() !== '0'
								? `${tableWait} min. wait`
								: 'No wait time'}
						</Link>
					</Styled.SeatingDetails>
				</Styled.SeatingContainer>
			);
		}
		return <></>;
	};

	return (
		<Styled.Container data-testid='location-pop-up'>
			<Styled.Header data-testid='location-brand-name'>Your {cmsComponents.shortName}</Styled.Header>
			<Styled.RestaurantNameContainer>
				<Styled.NameIconContainer>
					<Icon icon={cmsComponents.icons.iconLocation} customSize />
				</Styled.NameIconContainer>
				<Styled.RestaurantName
					data-testid='location-restaurant-name'
					aria-label={
						restaurantInfo.restaurantName ? `Restaurant name: ${restaurantInfo.restaurantName}` : undefined
					}
					tabIndex={0}
				>
					{restaurantInfo.restaurantName}
				</Styled.RestaurantName>
			</Styled.RestaurantNameContainer>
			<Styled.RestaurantAddress
				data-testid='location-restaurant-address'
				aria-label={
					restaurantInfo.restaurantAddress
						? `Restaurant address: ${restaurantInfo.restaurantAddress}`
						: undefined
				}
				tabIndex={0}
			>
				{restaurantInfo.restaurantAddress}
			</Styled.RestaurantAddress>
			<Styled.RestaurantHoursContainer>
				<Styled.HoursIconContainer>
					<Icon icon={IconClock} customSize />
				</Styled.HoursIconContainer>
				<Styled.RestaurantHours
					data-testid='location-restaurant-hours'
					aria-label={
						restaurantInfo.restaurantHours
							? `Restaurant hours: ${restaurantInfo.restaurantHours}`
							: undefined
					}
					aria-hidden={restaurantInfo.restaurantHours === null}
					tabIndex={0}
				>
					Restaurant hours: {restaurantInfo.restaurantHours}
				</Styled.RestaurantHours>
			</Styled.RestaurantHoursContainer>
			{renderSeatingDetails()}
			<Styled.ButtonsContainer>
				<Button
					text='Change location'
					padding='8px 0'
					variant='senary'
					data-testid='change-location'
					id='change-location'
					onClick={() => (window.location.href = 'https://locations.outback.com/search')}
					onKeyDown={(ev) => {
						if (ev.key == 'Enter') {
							window.location.href = 'https://locations.outback.com/search';
						}
					}}
				/>
				<Button
					text='View more info'
					padding='8px 0'
					variant='terciary'
					data-testid='view-more'
					id='view-more'
					onClick={() => (window.location.href = `https://locations.outback.com/${yext || 'search'}`)}
					onKeyDown={(ev) => {
						if (ev.key == 'Enter') {
							window.location.href = `https://locations.outback.com/${yext || 'search'}`;
						}
					}}
				/>
			</Styled.ButtonsContainer>
		</Styled.Container>
	);
};
