import { MOBILE_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Icon = styled.div<{ isLoading?: boolean }>`
	height: 24px;
	max-width: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all ease 0.1s;
	position: relative;

	& > *:first-child {
		color: ${({ theme }) => theme.orderUi.icons.iconHeaderColor};
	}

	${({ isLoading }) =>
		isLoading &&
		css`
			cursor: default;

			&:hover {
				opacity: 1;
			}
		`}
`;

export const AnimatedIconContainer = styled(Icon)`
	top: -1px;
	height: 14px;
	width: 40px;
`;

export const UserAccount = styled.button`
	background: none;
	border: none;
	padding: 5px 8px;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	cursor: pointer;
	appearance: auto;
	display: inline-block;

	@media (max-width: 767px) {
		padding: 5px 8px;
	}
`;

export const SignIn = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	color: ${({ theme }) => theme.orderUi.icons.iconHeaderColor};
	border: 1px solid ${({ theme }) => theme.orderUi.icons.iconHeaderColor};
	border-radius: 20px;
	padding: 5px 8px;
	padding-right: 14px;

	&:hover {
		opacity: 0.6;
	}
`;

export const SignInText = styled.span`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
`;

export const TooltipContainer = styled.div`
	max-width: 273px;

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		max-width: unset;	
	}
`;

export const TooltipTitle = styled.span`
	${({ theme }) => theme.orderUi.typography.headline.variant};
	${({ theme }) => theme.orderUi.typography.body.bodyLarge};
	display: block;
	margin-bottom: 18px;
`;

export const TooltipText = styled.span`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	text-align: center;
	display: block;
	margin-bottom: 18px;
`;

export const TooltipButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
