import { DESKTOP_SMALL_BREAKPOINT, MOBILE_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div`
	width: 273px;

	@media (max-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		width: 75vw;
		max-width: 294px;
	}

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const Header = styled.h2`
	margin-top: 0;
	font-family: 'BigBloke', sans-serif;
	font-size: 18px;

	font-weight: 400;
	line-height: 21.6px;
	margin-bottom: 16px;
	text-transform: uppercase;
	color: #121211;

	@media (max-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		font-size: 16px;
		line-height: 20px;
	}
`;

export const RestaurantNameContainer = styled.div`
	display: flex;
	align-items: top;
	gap: 4px;
	margin-bottom: 4px;
`;

export const NameIconContainer = styled.div`
	width: 27px;
	height: 19px;
	color: #891a1c;
`;

export const RestaurantName = styled.span`
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	color: #2c2c2b;

	@media (max-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		font-size: 14px;
		line-height: 18px;
	}
`;

export const RestaurantAddress = styled.div`
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	color: #121211;
	margin-bottom: 8px;
`;

export const RestaurantHoursContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	padding-left: 6px;
	margin-bottom: 8px;
	background-color: #f6f6f6;
	width: 90%;
	height: 20px;
`;

export const HoursIconContainer = styled.div`
	width: 14px;
	height: 14px;
	color: #2c2c2b;
`;

export const RestaurantHours = styled.span`
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	color: #2c2c2b;
`;

export const SeatingContainer = styled.div`
	background-color: black;
	display: flex;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	padding-left: 8px;
	margin-bottom: 18px;
	background-color: #f6f6f6;
	width: 90%;
	height: 20px;
`;

export const SeatingStatus = styled.span<{ status: boolean | null }>`
	${({ status }) => (status ? 'color: #2D8633;' : 'color: #891A1C;')}
`;

export const SeatingDetails = styled.div`
	color: #2d8633;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	gap: 16px;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
`;
